
  export const style={
    inpuStyle:{
      colorInput:"#064B50"
    },
    url:{
      urlClient:"https://hackateno.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/primary_log_hackateno.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: false,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Cateno Coin/Cateno Coins"
    },
    teamName:{
      name:"Hackateno"
    },
    hasToken: true,
    hostHeader: "hackateno"
  }
